(function () {
  "use strict";

  function heroSlider() {
    if (document.querySelector('.hero-slider')) {
      const swiper = new Swiper('.hero-slider .swiper', {
        loop: true,
        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true
        // },
        navigation: {
          nextEl: '.hero-slider .slider-button-next',
          prevEl: '.hero-slider .slider-button-prev',
        },
      })
    }
  }

  // Uluslararası Telefon Input
  function telefonInput() {
    // Slider Altı Form
    if (document.querySelector('#telInput')) {
      var input = document.querySelector("#telInput");
      window.intlTelInput(input, {
        utilsScript: "assets/scripts/utils.js"
      });
    }
    // Modal Form Tel
    if (document.querySelector('#modalTel')) {
      var input = document.querySelector("#modalTel");
      window.intlTelInput(input, {
        utilsScript: "assets/scripts/utils.js"
      });
    }
  }

  // Önce Sonra Slider
  function onceSonraSlider() {
    if (document.querySelector('.once-sonra-slider')) {
      const slider = new Swiper('.once-sonra-slider .swiper', {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        navigation: {
          nextEl: ".once-sonra-slider .slider-button-outline-next",
          prevEl: ".once-sonra-slider .slider-button-outline-prev",
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
          },
          992: {
            slidesPerView: 3,
          },
        }
      })
    }

  }

  // Video Slider
  function videoSlider() {
    if (document.querySelector('.video-slider')) {
      const slider = new Swiper('.video-slider .swiper', {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        navigation: {
          nextEl: ".video-slider .slider-button-outline-next",
          prevEl: ".video-slider .slider-button-outline-prev",
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
          },
          992: {
            slidesPerView: 3,
          },
        }
      })
    }

  }

  // Video Sayfa Slider
  function videoSayfaSlider() {
    if (document.querySelector('.video-sayfa-slider')) {
      const slider = new Swiper('.video-sayfa-slider .swiper', {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        navigation: {
          nextEl: ".video-sayfa-slider .slider-button-outline-next",
          prevEl: ".video-sayfa-slider .slider-button-outline-prev",
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
          },
          992: {
            slidesPerView: 3,
          },
        }
      })
    }

  }

  // Deneyim Slider
  function deneyimSlider() {
    if (document.querySelector('.deneyim-slider')) {
      const deneyimSlider = new Swiper('.deneyim-slider .swiper', {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        navigation: {
          nextEl: ".deneyim-slider .slider-button-outline-next",
          prevEl: ".deneyim-slider .slider-button-outline-prev",
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
          },
          992: {
            slidesPerView: 3,
          },
        }
      })
    }

  }

  function initAcc(elem, option) {
    document.addEventListener('click', function (e) {
      if (!e.target.matches(elem + ' .a-btn')) return;
      else {
        if (!e.target.parentElement.classList.contains('active')) {
          if (option == true) {
            var elementList = document.querySelectorAll(elem + ' .a-container');
            Array.prototype.forEach.call(elementList, function (e) {
              e.classList.remove('active');
            });
          }
          e.target.parentElement.classList.add('active');
        } else {
          e.target.parentElement.classList.remove('active');
        }
      }
    });
  }

  function uygulamalarSlider() {
    if (document.querySelector('.uygulamalar-slider')) {
      const slider = new Swiper('.uygulamalar-slider .swiper', {
        slidesPerView: 1,
        spaceBetween: 30,
        //loop: true,
        navigation: {
          nextEl: ".uygulamalar-slider .slider-button-next",
          prevEl: ".uygulamalar-slider .slider-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
          },
          992: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 4,
          },
        }
      })
    }
  }

  // Scroll top Button

  function scrollTop() {
    const btnScrollTop = document.getElementById('scrollTop')
    document.addEventListener('scroll', () => {
      if (window.scrollY > 1000) {
        btnScrollTop.classList.remove('hide-btn');
      } else {
        btnScrollTop.classList.add('hide-btn');
      }
    })

    btnScrollTop.addEventListener('click', () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    })
  }


  heroSlider()
  telefonInput()
  onceSonraSlider()
  videoSlider()
  videoSayfaSlider()
  deneyimSlider()
  initAcc('.accordion.v1', true);
  uygulamalarSlider()
  scrollTop()

})();